(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/expandable-box/assets/javascripts/components/body.js') >= 0) return;  svs.modules.push('/components/components/expandable-box/assets/javascripts/components/body.js');
"use strict";

const _excluded = ["children", "className", "contentId", "id", "isExpanded", "preventDeletion", "onExpand"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }

((svs, React, PropTypes) => {
  const {
    withContext
  } = svs.components.expandableBox;
  const Body = _ref => {
    let {
        children,
        className,
        contentId,
        id,
        isExpanded,
        preventDeletion,
        onExpand
      } = _ref,
      props = _objectWithoutProperties(_ref, _excluded);
    return React.createElement("div", _extends({
      "aria-hidden": isExpanded ? 'false' : 'true',
      className: className + (isExpanded ? '' : ' expandable-box-content-hide'),
      id: id ? id : contentId
    }, props), preventDeletion ? children : isExpanded ? children : null);
  };
  Body.defaultProps = {
    className: '',
    preventDeletion: false
  };
  svs.components.expandableBox.Body = withContext(Body);
})(svs, React, PropTypes);

 })(window);